import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Button from "../../components/atoms/Button";
import Loading from "../../components/atoms/Loading";
import { Title } from "../../components/atoms/Title/title";
import { Subtitle } from "../../components/atoms/Subtitle/subtitle";
import { GetPdfHandler } from "../../services/api/GetPdf/GetPdfHandler";
import { getUrlParams, isIEBrowser } from "../../components/utils";
import * as styles from "./styles.module.scss";
import iconDownloadPdf from "../../images/svg/save_alt_24px_white.svg";
import "promise-polyfill/src/polyfill";

class pdf extends Component {
  constructor(props) {
    super(props);
    let { reference, signature } = getUrlParams();

    this.state = {
      loading: true,
      success: false,
      reference,
      signature,
    };
  }

  getApplicationPdf = async (params) => {
    try {
      const PdfHandler = new GetPdfHandler({ type: "application/pdf" });
      const response = await PdfHandler.customAction(params);

      let file = new Blob([response.pdf], { type: "application/pdf" });
      let fileURL = URL.createObjectURL(file);

      if (isIEBrowser()) {
        window.navigator.msSaveOrOpenBlob(file, `${this.state.reference}.pdf`);

        fileURL = window.location.href;
      } else {
        var a = document.createElement("a");
        a.href = fileURL;
        a.setAttribute("download", `${this.state.reference}.pdf`);
        a.click();
      }

      this.setState({
        loading: false,
        success: true,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        success: false,
      });
    }
  };

  componentDidMount() {
    if (typeof this.state.reference !== "undefined") {
      this.getApplicationPdf({ reference: this.state.reference, signature: this.state.signature });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <Layout>
        <Wrapper
          compact
          center>
          {this.state.loading ? (
            <Loading full />
          ) : (
            <>
              {typeof this.state.reference !== "undefined" && (
                <>
                  {this.state.success ? (
                    <Message
                      title="Thank you for trusting us"
                      subtitle="If the download doesn't start automatically after a few seconds, use the following button."
                      button={{
                        label: "Download your form",
                        link: window.location.href,
                        icon: true,
                        download: false,
                      }}
                    />
                  ) : (
                    <Message
                      title="The link has expired"
                      subtitle="For security reasons the link you are looking for has expired. Please, contact us and we will send you another link."
                      button={{
                        label: "CONTACT US",
                        link: "/contact",
                        icon: false,
                        download: false,
                      }}
                    />
                  )}
                </>
              )}

              <Wrapper paddingTop>
                <Button
                  link="/home"
                  tertiary
                  label={"Go to homepage"}
                />
              </Wrapper>
            </>
          )}
        </Wrapper>
      </Layout>
    );
  }
}

export default pdf;

const Message = ({ title, subtitle, button: { link, download, label, icon } }) => {
  return (
    <>
      <Title
        hero
        tag="h1"
        content={title}></Title>
      <Subtitle
        big
        content={subtitle}
      />
      <Button
        link={link}
        label={label}
        icon={icon ? iconDownloadPdf : false}
        download={download}
        data-qa={"download-pdf"}
        className={styles.download_button}
        iconLeft
        primary
      />
    </>
  );
};

Message.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    download: PropTypes.bool,
    icon: PropTypes.bool,
  }),
};
